.presentation-container {
	max-width: 1400px;
	margin: 0 auto;
}

.presentation-container h1 {
	line-height: 1.5;
}

.presentation-container strong {
	font-weight: bold;
}
